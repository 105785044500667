.my_market {
  width: 100%;
}

.my_market .head_market {
  padding: 0 20px;
  height: 60px;
  display: grid;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border);
}

.my_market .head_market h1 {
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--black1);
}

.my_market .grid_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.my_market .grid_col .card_box {
  width: 100%;
  margin: 0;
}
