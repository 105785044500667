.modal_comp .modal-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
}

.modal_comp .not_modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_comp .modal {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 20%;
  overflow-y: auto;
  padding: 24px 20px;
  padding-bottom: 80px;
  background-color: var(--white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  animation: modalScreen 0.2s ease;
}

@keyframes modalScreen {
  0% {
    bottom: -500px;
  }
  100% {
    bottom: 0;
  }
}

.modal_comp .close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal_comp .close:hover,
.modal_comp .close:focus {
  color: #a3a0a0;
  text-decoration: none;
}
