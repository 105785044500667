.payments_general {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.payments_general .payments_general_balance {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 20px;
}

.payments_general .payments_general_balance p {
    font-size: 12px;
    color: #D9D9D9;
}

.payments_general span {
    font-size: 12px;
    color: #e22717;
    cursor: pointer;
}

.payments_general .payments_general_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.payments_general h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}