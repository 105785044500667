.ads_post {
  max-width: 990px;
  width: 100%;
  padding-bottom: 120px;
}

.ads_post .ads_post_container {
  padding: 0 20px;
}

.ads_post .box_market {
  margin-top: 8px;
}

.ads_post .head_market {
  padding: 0 20px;
  height: 60px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border);
}

.ads_post .head_market h1 {
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--black1);
}

.ads_post .select_market,
.ads_post .input_market {
  margin-top: 5px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
  border: 1px solid var(--border);
  padding: 12px;
}

.ads_post .select_market {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: var(--black);
  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
}

.ads_post .select_market:focus {
  border-color: var(--orange);
}

.ads_post .input_market:focus {
  border: 1px solid #e22717;
}

.ads_post .input_market.text {
  height: 120px;
  max-height: 120px;
}

.ads_post .button_form {
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background: #e22717;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ads_post .btn_add {
  padding: 12px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  gap: 8px;
}

.ads_post .photo-grid {
  padding-top: 12px;
  display: flex;
  overflow-y: hidden;
  gap: 10px;
}

.ads_post .photo_box:first-child {
  margin-left: 20px;
}

.ads_post .photo_box:last-child {
  margin-right: 20px;
}

.ads_post .photo-container {
  width: 125px;
  display: flex;
  flex-direction: column;
}

.ads_post .photo {
  width: 125px;
  height: 125px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  border: 1px solid rgb(0, 0, 0, 0.1);
}

.ads_post .photo-buttons {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ads_post .btn_photo {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  background: #e22717;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid transparent;
}

.ads_post .btn_photo.delete {
  color: var(--yellow);
  background: transparent;
  border: 1px solid var(--border);
}

.ads_post .modal_photo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation: modals 0.3s ease;
  z-index: 9999;
}

@keyframes modals {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ads_post .modal_not {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.8);
  z-index: 1;
}

.ads_post .modal_photo_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 50vh;
  z-index: 2;
}

.ads_post .modal_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ads_post .red {
  color: red;
  font-size: 14px;
  font-weight: 400;
  text-align: end;
}

.ads_post .currency-selection {
  display: flex;
  gap: 10px;
}

.ads_post .currency-option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ads_post .currency-option input[type="radio"] {
  margin-right: 8px;
}

.ads_post .currency-option label {
  font-size: 16px;
  cursor: pointer;
}

.box_market_select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 10px;
}

.label_market {
  display: block;
  font-weight: 600;
  font-size: 16px;
}

.currency-option input[type="radio"] {
  margin-right: 10px;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #e22717;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.currency-option input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #e22717;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
}

/* Стиль для меток радио-кнопок */
.currency-option label {
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

/* Стиль для текстового ввода */
.input_market {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}
