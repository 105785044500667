.referal_container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 10px 20px 100px;
}

.referal_container > div:nth-child(1) {
  padding: 10px 10px;
    width: 100%;
    height: 60px;
    position: fixed;
    inset: 0;   
    background-color: #e22717;
    z-index: 1;
}

.referal_container > div:nth-child(2) {
  margin-top: 60px;
}

.referal_container > div > span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.custom-progress-bar {
  padding-top: 5px;
}

.container_radial {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.container_radial > div {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.referal_container .referal_friends > h1 {
  text-align: center;
  margin: 20px;
}

.ref_friends_contain h2 {
  color: #757575;
}

.ref_friends_contain span {
  color: var(--orange);
  padding: 0 5px;
}

.ref_friends_contain .friend_block {
  margin: 5px;
  border: 1px solid #757575;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.ref_friends_contain .friend_block p {
  color: #757575;
  font-size: 10px;
}

.ref_friends_contain .friend_block > div > div:nth-child(1) {
  border-bottom: 1px solid #757575;
  margin-bottom: 5px;
}

.ref_friends_contain .bonuses_contain {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.ref_friends_contain .bonuses_contain h3 {
  color: #757575;
  font-size: 10px;
}

.ref_friends_contain .bonuses_contain span {
  color: rgb(80, 214, 80);
}
