.features-card {
    background: var(--red, #e22717);
    border-radius: 10px;
    padding: 30px;
    margin-right: 24px;
    width: 445px;
    height: 285px;
    font-weight: 600;
    color: #fff;
    display: grid;

    @media screen and (max-width: 500px) {
        width: 275px;
        height: 130px;
        padding: 20px;
        margin-right: 10px;
    }

    div {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 50px;
            height: 50px;

            @media screen and (max-width: 500px) {
                width: 24px;
                height: 24px;
            }
        }

        span {
            font-size: 50px;

            @media screen and (max-width: 500px) {
                font-size: 24px;
            }
        }

        h3 {
            font-size: 20px;
            text-transform: uppercase;

            @media screen and (max-width: 500px) {
                font-size: 12px;
            }
        }
    }

    p {
        font-size: 16px;
        line-height: 24px;

        @media screen and (max-width: 500px) {
            font-size: 8px;
            line-height: 12px;
        }
    }
}
