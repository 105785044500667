@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Glasten';
  src: url('./fonts/Glasten/glasten.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

button {
  outline: none;
}

input {
  outline: none;
}

h5 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding: 60px 30px 25px;
  text-transform: uppercase;
}

@media (max-width: 500px) {
  h5 {
    font-size: 16px;
    padding: 40px 25px 15px;
  }
}

   

:root {
  --white: #fff;
  --black1: #1c1b1e;
  --black: #050506;
  --orange: #e48a21;
  --gray: #d1d1d2;
  --border: #dadada;
  --gray-color: #868790;
  --yellow: linear-gradient(to left, #ff9f0e, #ffcd04);
}
