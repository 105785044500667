.settings {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 0 20px;
}

.settings .head {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #e22717;
  z-index: 1;
}

.settings .head svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.settings .head span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.settings > div:nth-child(2){
  margin-top: 80px;
}

.settings .settings_block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settings .leng {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.settings .leng h2 {
  margin-bottom: 10px;
}

.settings .box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 10px;
  padding: 15px 10px;
  cursor: pointer;
}

.settings .box .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.settings .box .flex div {
  width: 33px;
  height: 32px;
}

.settings .box .flex div img {
  border-radius: 50%;
}

.settings .box .text {
  font-size: 16px;
  font-weight: 400;
}

.settings .box .icon {
  font-size: 24px;
}

.settings .box span {
  font-size: 16px;
  font-weight: 300;
  color: #b4b4b4;
}

.ant-switch.custom-switch.enabled {
  background-color: #52c41a;
}
.ant-switch.custom-switch.enabled:hover {
  background-color: #52c41a !important;
}

.ant-switch {
  background-color: #686868;
}

.ant-switch:hover {
  background-color: #686868 !important;
}
