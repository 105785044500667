.contain-with-drawal-page {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.contain-with-drawal-page > div:nth-child(1) {
  color: #fff;
  padding: 10px 0;
    width: 100%;
    height: 60px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;   
    background-color: #e22717;
    z-index: 1;
}

.contain-with-drawal-page > div:nth-child(1) svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.contain-with-drawal-page  span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.coin-with-drawal {
  margin: 20px 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin-with-drawal_img {
  width: 100px;
}

.contain-with-drawal-block {
  margin-top: 60px;
  padding: 20px;
}

.contain-with-drawal-block > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contain-with-drawal > div input {
  background: none;
  border-bottom: 1px solid #4b4b4b;
  width: 100%;
  margin: 10px 0;
}

.contain-with-drawal {
  width: 100%;
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.balance-with-drawal {
  font-size: 12px;
  font-weight: 500;
}

.error-text-with-drawal {
  font-size: 10px;
  color: red;
  margin-left: 10px;
}

.text-main-with-drawal {
  font-size: 12px;
  font-weight: 500;
  color: #9D9D9D;
}

.bottom-btn-with-drawal {
  display: grid;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.bottom-btn-with-drawal button:nth-child(2) {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
