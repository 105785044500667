.catchoises-container {
  padding: 20px 0;
  display: flex;
  overflow-y: hidden;
  background: transparent;
  gap: 10px;
  position: relative;
  z-index: 1;
}

.catchoises-container::-webkit-scrollbar {
  display: none;
}

.catchoises-container .cate {
  padding: 10px;
  border-radius: 10px;
  background: var(--light-grey, #F3F3F3);
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.catchoises-container .cate:active {
  background-color: #e22717;
  color: white;
  transition: 0.2s all;
}

.catchoises-container .cate p {
  font-size: 10px;
  font-weight: 400;
  white-space: nowrap;
}
