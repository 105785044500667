.storis {
  margin: 0 auto;
  padding: 10px 0;
  min-height: 95px;
  width: 100%;
}

.storis .storis_blockss {
  display: flex;
  overflow-y: hidden;
  gap: 10px;
}

.storis .flip {
  display: flex;
  gap: 10px;
}

.skeleton-stories {
  display: flex;
}

.storis .storis_content {
  width: 90px;
  height: 90px;
  border-radius: 19px;
  border: 1px solid #e22717;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px 0px #E22717;
  margin: 10px 0;
}

.storis .storis_content img {
  width: 90px;
  height: 88.9px;
  object-fit: cover;
  object-position: center;
  border-radius: 18px;
}

.storis .storis_content:first-child {
  margin-left: 10px;
}

.storis .storis_content:last-child {
  margin-right: 20px;
}

.storis .statuses {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: flex;
  align-items: flex-start;
  background: black;
}

.storis .not_status {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.486);
}

.storis .status img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.storis .status video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.storis .close {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 45px;
  color: #686868;
  z-index: 99999999;
}

.storis ::-webkit-scrollbar {
  display: none;
}
