.images-sign-in {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  background-color: var(--white);
  color: black;
}

.coin-sign-in {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin-sign-in img {
  width: 180px;
}

.submit-sign-in h1 {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  margin: 40px 0 20px;
}

.inputs-sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  width: 300px;
  margin: 0 auto;
}

.inputs-sign-in input {
  width: 320px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 20px;
  font-size: 18px;
  border: 1px solid #000;
}

.inputs-sign-in input:focus {
  border: 1px solid #E22717;
  transition: 0.4s;
}

.link {
  text-decoration: none;
  align-self: flex-end;
  font-size: 14px;
  font-weight: 400;
}

.sign-in {
  width: 320px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #E22717;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 35px;
}

.password-sign-in {
  position: relative;
}

.span-icon {
  position: absolute;
  top: 17px;
  right: 15px;
  cursor: pointer;
}

.footer-text {
  font-size: 14px;
  font-weight: 500;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.footer-text a {
  color: #E22717;
}
