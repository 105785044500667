.change_password {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 0 20px;
}

.change_password .change_block {
  margin-top: 80px;
}

.change_password .label_form {
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 400;
}

.change_password .input_form {
  margin-top: 6px;
  width: 100%;
  outline: none;
  padding: 15px 10px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  border: 1px solid var(--grey, #D9D9D9);
}

.change_password .input_form::placeholder {
  color: #1a1a1a;
  font-weight: 400;
}

.change_password .button_form {
  margin-top: 20px;
  outline: none;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
  color: #fdfdfd;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.change_password .input_box {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.change_password .span-icon {
  z-index: 1;
  position: absolute;
  top: 45px;
  right: 14px;
  font-size: 20px;
  cursor: pointer;
}

.change_password .head {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #e22717;
  z-index: 1;
}

.change_password .head svg {
  position: fixed;
  left: 10px;
   z-index: 1;
}

.change_password .head span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.change_password .text {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #353535;
}
