.category-page {
  padding: 10px;
}

.category-page .category-page_top {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  inset: 0;
  background-color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.category-page .search {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
  border: 1px solid var(--border);
  padding-left: 50px;
}

.category-page .relative_input {
  margin: 60px 0 30px;
  position: relative;
}

.category-page .search::placeholder {
  color: var(--gray-color);
}

.category-page .icon {
  width: 24px;
  height: 24px;
}

.category-page .absolute {
  position: absolute;
  top: 0;
  left: 15px;
  bottom: 0;
  margin: auto;
}
