.contain-header {
  position: relative;
  width: 100%;
  height: 60px;
}
.header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  color: black;
  z-index: 999;
}

.profile-name {
  display: flex;
  align-items: center;
  font-size: 15px;
  gap: 10px;
  cursor: pointer;
}

.profile-photo-header {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #c1c1c1;
}

.count_notification {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgb(230, 74, 74);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  position: absolute;
  right: 0;
}

.menu {
  width: 70%;
  height: 100vh;
  padding: 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: #fff;
  animation: openAnim 0.9s linear forwards;
}

.menu.closing {
  animation: closeAnim 0.9s linear forwards;
}

.menu ul {
  overflow-y: auto;
  scrollbar-width: none;
  height: 100vh;
  padding-bottom: 150px;
}

.menu ul::-webkit-scrollbar {
  display: none;
}

.menu ul li a {
  color: #1A1A1A;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
  white-space: nowrap;
}

.menu_close {
  display: grid;
  justify-items: end;
}

.menu_bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 1%;
}
@keyframes openAnim {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 70%;
    opacity: 1;
  }
}

@keyframes closeAnim {
  0% {
    width: 70%;
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}