.expenses_container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 15px;
  font-size: 15px;
  padding-bottom: 100px;
}

.expenses_container > div:nth-child(1) {
    width: 100%;
    height: 60px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: #e22717;
  }

  .expenses_container > div:nth-child(1) svg {
    position: fixed;
    left: 10px;
    z-index: 1;
  }
  
  .expenses_container > div > span {
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  
  .expenses_container > div:nth-child(2){
    margin-top: 60px;
  }

  .expenses_container .expenses_container__search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey, #D9D9D9);
    border-radius: 5px;
    padding: 8px 10px;
  }

  .expenses_container .expenses_container__search > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }