.discount {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.discount > div:nth-child(1) {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #e22717;
  color: white;
}

.discount > div:nth-child(1) svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.discount > div:nth-child(1) span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.discount .container_2 {
  padding: 0 20px;
  padding-bottom: 120px;
  margin-top: 80px;
}

/* .discount .discount_block {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
} */

/* .discount .discount_block h5 {
  color: #949494;
  font-size: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 2px;
} */

.discount .discount_box {
  width: 140px;
  height: 100px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.discount .discount_box img {
  position: static;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  border: 1px solid #a5a5a568;
}

.discount h2 {
  margin: 20px 0 10px;
  font-size: 16px;
  font-weight: 600;
  color: #353535;
}

.discount .text_discount {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #353535;
  line-height: 19px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  z-index: 1;
}

.discount .text_dis {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
}

.discount .cateing {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.discount .cateing .cate {
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 80px;
  font-size: 12px;
}

.discount_block div {
  position: relative;
  margin-right: 5px;
}
.discount_block div .absolute {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 10px;
  font-weight: 700;
  z-index: 10;
  width: 42px;
  height: 19px;
  background-color: #e22717;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

/* .discount_block div h2 {
  position: absolute;
  bottom: 20px;
  right: 0px;
  background-color: #ff9f0e4c;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 3px;
  font-size: 10px;
} */
