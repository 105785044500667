.store-home {
    max-width: 1920px;
    margin: 0 auto;

    .target_modal_bg {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                z-index: 1000;
            }

    .store-home-start {
        padding: 0 30px;

        @media (max-width: 800px) {
            padding: 0;
        }

        .store-home-start-content {
            background-color: #e22717;
            border-radius: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            align-items: center;
            padding: 20px 50px;
            color: #fff;

            @media screen and (max-width: 800px) {
                grid-template-columns: 1fr;
                padding: 40px 25px;
                border-radius: 0;
            }

            h1 {
                max-width: 377px;
                font-size: 40px;
                font-weight: 400;
                line-height: 51px;
                margin-bottom: 15px;
                text-transform: uppercase;

                @media screen and (max-width: 800px) {
                    text-align: center;
                    max-width: 100%;
                }

                @media screen and (max-width: 500px) {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 36px;
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                white-space: pre-line;

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                    font-weight: 600;
                }
            }

            > div > div {
                display: flex;
                align-items: end;
                gap: 20px;

                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                    align-items: center;
                }

                button {
                    border: 2px solid #ffffff;
                }
            }

            img {
                height: 400px;
                @media screen and (max-width: 800px) {
                    display: none;
                }
            }
        }
    }

    .store-home-target {
        display: grid;
        justify-content: center;
        gap: 50px;
        padding: 0 30px;

        @media screen and (max-width: 800px) {
            padding: 0 25px;
        }

        @media screen and (max-width: 500px) {
            gap: 20px;
        }

        p {
            font-size: 20px;
            text-align: center;

            @media screen and (max-width: 500px) {
                font-size: 12px;
            }
        }

        .store-home-target-slider {
            display: flex;
            flex-wrap: wrap;
            align-items: start;
            gap: 30px;

            @media screen and (max-width: 500px) {
                gap: 20px;
            }

            div {
                display: grid;
                justify-items: center;
                gap: 10px;

                div {
                    max-width: 155px;

                    @media screen and (max-width: 500px) {
                        max-width: 90px;
                    }

                    img {
                        border-radius: 12px;
                        width: 100%;
                        height: 155px;

                        @media screen and (max-width: 500px) {
                            height: 90px;
                        }
                    }
                }

                span {
                    font-size: 20px;
                    font-weight: 600;

                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                    }
                }
            }

            div:last-child {
                border: 2px solid var(--red, #e22717);
                border-radius: 10px;
                padding: 23px;
                font-size: 20px;
                font-weight: 600;
                color: #e22717;
                text-align: center;
                height: 155px;
                cursor: pointer;

                @media screen and (max-width: 500px) {
                    height: 90px;
                    font-size: 12px;
                    padding: 12px;
                }
            }
        }
        .target_modal {
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1001;
            display: grid;
            justify-items: end;
            gap: 10px;

            form {
                display: grid;
                gap: 10px;

                input {
                    display: block;
                    border: 1px solid #E22717;
                    border-radius: 10px;
                    padding: 10px;
                    font-size: 12px;
                }

                .target_modal_button {
                    background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
                    border-radius: 10px;
                    padding: 10px;
                    color: #ffffff;
                    text-align: center;
                }
            }
        }
    }

    .partners {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 30px;
        justify-items: center;
        padding: 0 30px;

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            padding: 0 25px;
        }
    }

    .what {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        gap: 20px;
        padding: 0 30px;

        @media screen and (max-width: 800px) {
            padding: 0 25px;
        }

        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
            gap: 0;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 25px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 19px;
                margin-bottom: 15px;
            }
        }

        button {
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
    }

    .advantages-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 60px 30px 25px;

        @media screen and (max-width: 800px) {
            padding: 40px 25px 15px;
        }

        h2 {
            cursor: pointer;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;

            @media screen and (max-width: 500px) {
                font-size: 14px;
            }
        }
    }

    .store-advantages {
        display: flex;
        gap: 30px;
    }

    .store-shariat {
        display: grid;
        justify-items: center;
        gap: 25px;
        padding: 0 30px;

        @media screen and (max-width: 800px) {
            padding: 0 25px;
        }

        p {
            font-size: 20px;
            line-height: 25px;
            text-align: center;

            @media screen and (max-width: 800px) {
                > br {
                    display: none;
                }
            }

            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 19px;
            }
        }
    }
    
            .store-map-img {
                position: absolute;
                z-index: 1;
                left: 50%;
                height: 100%;
    
                @media screen and (max-width: 800px) {
                    display: none;
                }
    
                img {
                    height: 100%;
                }
            }

    .store-map {
        display: grid;
        gap: 30px;
        position: relative;

        @media screen and (max-width: 500px) {
            padding-left: 25px;
        }

        .store-map-content {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 500px) {
                flex-direction: column;
                gap: 15px;
            }

            .store-map-box1 {
                border: 1px solid var(--grey, #d9d9d9);
                background-color: #e22717;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                display: flex;
                justify-content: end;
                align-items: center;
                gap: 8px;
                padding: 40px;
                width: 500px;

                @media screen and (max-width: 1100px) {
                    width: 200px;
                }

                @media screen and (max-width: 500px) {
                    background-color: #fff;
                    border: none;
                    width: 100%;
                    padding: 0;
                    justify-content: start;
                }

                p {
                    text-align: center;
                    font-size: 28px;
                    font-weight: 600;
                    color: #fff;
                    text-transform: uppercase;

                    @media screen and (max-width: 1100px) {
                        font-size: 12px;
                    }

                    @media screen and (max-width: 500px) {
                        color: #e22717;
                    }
                }

                hr {
                    width: 100%;
                    background-color: #d9d9d9;
                    display: none;

                    @media screen and (max-width: 500px) {
                        display: block;
                    }
                }
            }

            .store-map-box2 {
                border: 1px solid var(--grey, #d9d9d9);
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                width: 500px;
                padding: 20px 35px 0;

                @media screen and (max-width: 1100px) {
                    width: 300px;
                }

                @media screen and (max-width: 500px) {
                    border: none;
                    width: 100%;
                    padding: 0;
                }

                p {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    white-space: pre-line;

                    @media screen and (max-width: 1100px) {
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .store-express {
        display: grid;
        justify-items: center;
        gap: 30px;
        padding: 0 30px;

        @media screen and (max-width: 800px) {
            padding: 0 25px;
        }

        @media screen and (max-width: 500px) {
            gap: 20px;
        }

        > p {
            font-size: 20px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
            }
        }

        .store-express-content {
            span {
                color: #e22717;
                font-size: 20px;
                font-weight: 600;

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }

            .store-express-text {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                gap: 50px;
                margin-top: 25px;

                @media screen and (max-width: 500px) {
                    grid-template-columns: 1fr;
                    gap: 15px;
                    margin-top: 15px;
                }

                p {
                    font-size: 16px;
                    color: #353535;
                    line-height: 24px;

                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                        line-height: 19px;
                    }

                    @media screen and (max-width: 500px) {
                        > br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
