.store_management {
    .store_management_text {
        background-color: #e22717;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;

        @media screen and (max-width: 800px) {
            margin-top: 0;
        }

        > div {
            background-color: #fff;
            padding: 25px 50px 0;
            max-width: 1000px;

            @media screen and (max-width: 800px) {
                padding: 20px 25px 0;
                background: transparent;
            }

            p {
                font-size: 20px;
                line-height: 25px;
                width: 100%;
                margin-bottom: 25px;
                color: #353535;
                white-space: pre-line;

                @media screen and (max-width: 800px) {
                    color: #fff;
                }

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                    line-height: 19px;
                    margin-bottom: 20px;
                    font-weight: 600;
                }

                span {
                    font-size: 30px;
                    font-weight: 600;
                    color: #e22717;

                    @media screen and (max-width: 800px) {
                        color: #fff;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .store_values {
        padding: 0 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        @media screen and (max-width: 800px) {
            padding: 0 25px;
            gap: 10px;
        }

        > div {
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            padding: 30px;
            display: grid;
            align-items: center;
            gap: 20px;

            @media screen and (max-width: 800px) {
                padding: 20px;
                gap: 10px;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #e22717;

                svg,
                img {
                    width: 40px;
                    height: 40px;

                    @media screen and (max-width: 800px) {
                        width: 24px;
                        height: 24px;
                    }
                }

                span {
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: uppercase;
                    word-wrap: break-word;

                    wbr {
                        display: none;

                        @media screen and (max-width: 800px) {
                            display: block;
                        }
                    }

                    @media screen and (max-width: 800px) {
                        font-size: 10px;
                        gap: 5px;
                    }
                }
            }

            p {
                font-size: 16px;

                @media screen and (max-width: 800px) {
                    font-size: 9.24px;
                }
            }
        }
    }

    .store_management_team {
        padding: 0 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 40px; 

        @media screen and (max-width: 800px) {
            padding: 0 25px;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }

        .store_management_team_item {
            display: grid;
            justify-items: center;
            gap: 10px;
            font-weight: 600;
            text-align: center;

            @media screen and (max-width: 500px) {
                gap: 5px;
            }

            div {
                width: 120px;
                border-radius: 10px;
                background-color: #d9d9d9;

                @media screen and (max-width: 500px) {
                    width: 80px;
                }
                
                img {
                    width: 100%; 
                    height: 120px;
                    border-radius: 10px;

                    @media screen and (max-width: 500px) {
                        height: 80px;
                    }
                }
            }

            span {
                font-size: 20px;
                color: #e22717;

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }

            p {
                font-size: 16px;

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }
        }
    }

    .store_our_team {
        padding: 0 30px 100px;
        display: grid;
        justify-content: center;

        @media screen and (max-width: 800px) {
            padding: 0 25px 40px;
        }

        P {
            font-size: 20px;
            line-height: 25px;
            color: #353535;
            max-width: 920px;
            white-space: pre-line;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 19px;
            }
        }
    }
}
