.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.loading_div {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
