.announcements-page {
}

.announcements-page .announcements-block {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.announcements-page .announcements-block div {
  width: 50%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  gap: 5px;
  font-size: 12px;
}

.announcements-page .announcements-title {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.announcements-page .announcements-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 30px 0;
}
