.contain-buyasman-all {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.contain-buyasman-all > div:nth-child(1) {
  color: #fff;
  padding: 10px 0;
    width: 100%;
    height: 60px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;   
    background-color: #e22717;
    z-index: 1;
}

.contain-buyasman-all > div:nth-child(1) svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.contain-buyasman-all span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.contain-buyasman-all > div:nth-child(2) {
  margin-top: 60px;
}

.contain-buyasman {
  width: 100%;
  height: 100vh;
  padding: 20px 20px;
  position: relative;
}

.contain-buyasman > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contain-buyasman > p {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2px;
}
.buyasman-payblock {
  width: 100%;
  /* height: 88px; */
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.payment-usdt-block {
  font-size: 12px;
  font-weight: 500;
  
}

.buyasman-payblock > div > img {
  width: 40px;
}

.buyasman-payblock > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coin-buyasman {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 40px 0 40px;
}
.coin-buyasman_img {
  width: 100px;
}

.second-buyasman-payblock {
    display: grid;
    justify-items: center;
}

.balance-buyasman {
  font-size: 12px;
  color: #9D9D9D;
  font-weight: 500;
  padding-bottom: 10px;
}

.button-send-buyasman {
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 5px;
}

.bottom-btn-buyasman {
  font-size: 14px;
  font-weight: 600;
}

.text-main-buyasman {
  color: #ededed;
  font-size: 14px;
}

.error-text-buyasman {
  color: red;
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: inherit;
  border: 1px dashed #1A1A1A;
  padding: 15px;
  border-radius: 10px;
  font-size: 12px;
  width: 350px;
}

input[type="file"] {
  display: none;
}
