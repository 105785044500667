.contain-details-all {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.contain-details-all > div:nth-child(1) {
  color: #fff;
  padding: 10px 0;
    width: 100%;
    height: 60px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;   
    background-color: #e22717;
    z-index: 1;
}

.contain-details-all > div:nth-child(1) svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.contain-details-all span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.contain-details-all > div:nth-child(2) {
  margin-top: 60px;
}

.contain-details {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 20px 20px;
  position: relative;
}

.contain-details > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contain-details > p {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2px;
}
.contain-payblock {
  width: 100%;
  /* height: 100px; */
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.contain-payblock > div > img {
  width: 40px;
}

.contain-payblock > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.coin-perevod {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 40px 0 40px;
}
.coin-perevod_img {
  width: 100px;
}

.contain-payblock input {
  background: inherit;
  border-bottom: 1px solid var(--grey, #D9D9D9);
  padding: 5px;
  font-size: 12px;
  width: 200px;
}

.balance-perevod {
  font-weight: 500;
  font-size: 12px;
  color: #9D9D9D;
  padding-bottom: 10px;
}

.button-send-perevod {
  width: 100%;
  height: 50px;
  background-color: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
  color: white;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
}

.bottom-btn-perevod {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.button-scan {
  width: 100%;
  border: 1px solid #1A1A1A;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: grid;
  justify-items: center;
}

.text-main-perevod {
  font-size: 12px;
  font-weight: 500;
}

.error-text-perevod {
  color: red;
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.error-text-user {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: 1px;
}
.modalsss {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #000000;
}

.modal-contentsss {
  background-color: #fefefe;
  margin: 50% auto;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.closesss {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closesss:hover,
.closesss:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-messagesss {
  font-size: 18px;
  text-align: center;
}

.button-closesss {
  background-color: #aaa;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.button-closesss:hover {
  background-color: #555;
}
