.discount_detail {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 0 20px 50px;
}

.discount_detail .btn {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 45px;
  background-color: #df4d40;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.discount_detail .head {
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;
  align-items: center;
  inset: 0;
  background-color: #e22717;
  color: white;
  padding-left: 10px;
}

.discount_detail .head img {
  width: 24px;
  height: 24px;
}

.discount_detail > div:nth-child(2) {
  margin-top: 80px;
}

.discount_detail .image {
  width: 100%;
  height: 165px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #707070;
}

.discount_detail .text_discount {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}
.discount_detail .text_discount span {
  color: #d9d9d9;
}

.discount_detail .text_discount h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.discount_detail .details_partners h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
}

.details_partners_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 12px;
  margin-bottom: 10px;
}

.discount_detail .discount_sale {
  width: 100%;
  padding: 9.5px 0;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
  margin: 20px 0 40px;
  color: #fff;
}
