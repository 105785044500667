.category {
  padding: 0px 10px 20px;
  display: flex;
  overflow-y: hidden;
  background: transparent;
  gap: 8px;
  position: relative;
  z-index: 1;
}

.category .category_button {
  width: 100%;
  height: 35px;
  background-color: #ececec;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
}

.category::-webkit-scrollbar {
  display: none;
}
