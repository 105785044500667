.store-footer {
    max-width: 1920px;
    margin: 0 auto;
    padding: 100px 30px 120px;

    @media screen and (max-width: 800px) {
        padding: 40px 25px 100px;
    }

    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .store-footer-logo {
            font-size: 81px;
            font-family: 'Glasten', sans-serif;
            color: #e22717;

            @media screen and (max-width: 500px) {
                font-size: 56px;
            }
        }

        ul li a {
            font-size: 18px;
            color: #1a1a1a;
            margin-bottom: 20px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
            }
        }

        .store-footer-social {
            a {
                margin-bottom: 20px;
                color: #1a1a1a;
                font-size: 18px;
                display: flex;
                align-items: center;
                gap: 5px;

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }
        }
    }

    > div:last-child {
        font-size: 18px;
        margin-bottom: 20px;

        @media screen and (max-width: 500px) {
            font-size: 8px;
            margin-bottom: 10px;
            margin-top: 40px;
        }
    }
}