.profile {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.profile_top {
 height: 126px;
 background-color: #e22717;
}

.profile h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding-top: 24px;
}

.profile .profile_image {
  margin: 0 auto;
  margin-top: 25px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 3px solid #ffffff;
  margin-top: -48px;
}

.profile_content {
  padding: 0 20px;
  padding-top: 20px;
}

.profile .name {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.profile .change_photo {
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #e22717;
  padding-top: 10px;
}

.profile .profile_block {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.profile .profile_block .box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey, #D9D9D9);
  padding: 15px 10px;
  cursor: pointer;
}

.profile .save .box.top1 {
  border-radius: 10px 10px 0 0;
}
.profile .box.top {
  border-radius: 0 0 10px 10px;
}

.profile .box .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.profile .box .flex img {
  width: 24px;
  height: 24px;
}

.profile .box .label {
  font-size: 12px;
  font-weight: 500;
  color: var(--gray);
}

.profile .box .text {
  font-size: 16px;
  font-weight: 600;
}

.profile .box .icon {
  font-size: 24px;
}

.profile .block_logout .title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  /* color: #ff0000; */
}

.profile .block_logout .text {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #9D9D9D;
}

.profile .block_logout .flex {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.profile .block_logout .flex .btn {
  width: 100%;
  padding: 10px;
  border: 2px solid #17E243;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #17E243;
  cursor: pointer;
  border-radius: 50px;
}

.profile .block_logout .flex .btn.red {
border: 2px solid var(--red, #E22717);
  color: #E22717;
}

.profile .photo_big {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsla(0, 0%, 0%, 0.4);
  z-index: 99999;
}

.profile .photo_big img {
  position: absolute;
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.profile .discount_box_skeleton_head,
.profile .discount_box_skeleton {
  width: 100%;
  height: 55px;
  margin-bottom: 15px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.profile .discount_box_skeleton_head::before,
.profile .discount_box_skeleton::before,
.profile .discount_box_skeleton_head::after,
.profile .discount_box_skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.profile .discount_box_skeleton_head::before,
.profile .discount_box_skeleton::before {
  background: linear-gradient(
    45deg,
    rgba(66, 66, 66, 0.7),
    rgba(51, 51, 51, 0.7),
    rgba(23, 23, 23, 0.7),
    rgba(50, 50, 50, 0.7),
    rgba(53, 53, 53, 0.7),
    rgba(32, 32, 32, 0.7)
  );
  background-size: 300% 300%;
  animation: skeletonGradient 2s ease infinite;
  z-index: 1;
}

.profile .discount_box_skeleton_head::after,
.profile .discount_box_skeleton::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  animation: skeletonShadow 0.7s ease infinite;
  z-index: 2;
}

@keyframes skeletonGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes skeletonShadow {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.profile .discount_box_skeleton_head {
  margin: 0 auto;
  margin-top: 25px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.container {
  max-width: 990px;
  width: 90%;
}
