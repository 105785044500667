.favorite_market {
  width: 100%;
}

.favorite_market .head_market {
  padding: 0 20px;
  height: 60px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border);
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: #fff;
}

.favorite_market .head_market h1 {
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--black1);
}

.favorite_market .market {
  padding-top: 0;
}

.favorite_market .market .market_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px;
}

.favorite_market .favorite_block {
  padding: 80px 0px 100px;
}