.scanner-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.scanner-container > div:nth-child(1) {
  width: 100%;
  height: 60px;
  position: fixed;
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  inset: 0;
  background-color: #e22717;
  padding-left: 10px;
  z-index: 1;
}

.scanner-container span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.contain-scan_cam {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin-top: 60px;
  padding: 20px;
}

.scan_cam_title {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-shadow: 0px 2px 8.3px 0px #0000000D;
  border-radius: 10px;
}

.scan_cam_title p {
  width: 100%;
  padding: 6px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.scan_cam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.scan_cam > h1 {
  font-weight: 700;
}

.scan_cam_active {
  background: var(--light-grey, #F3F3F3);
}

.container-qr-block {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 20px;
  margin: 0 auto;
  /* margin-top: 60px; */
}

.container-qr-block > h1 {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
}

.container-qr-block > p {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.qr-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.qr-block h1{
  padding-top: 10px;
}

.qr-block img {
  width: 300px;
  height: 300px;
}

.share-button-qr {
  width: 100%;
  padding: 10px;
  background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
  border-radius: 10px;
  margin: 20px 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}