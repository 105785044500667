.support {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 0 20px;
}

.support .head {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #e22717;
  color: white;
}

.support .head svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.support .head span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.support h2 {
  margin-top: 25px;
  font-size: 18px;
  font-weight: 400;
}

.support .support_block {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.support .block {
  padding: 20px 0;
}

.support .block h2 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.support .block .modal_top {
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}

.support .block .modal_bottom {
  border-radius: 0 0 10px 10px;
}

.support .box {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 10px;
  padding: 15px 10px;
  cursor: pointer;
}

.support .save .box.top1 {
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  border: 1px solid var(--grey, #D9D9D9);
}

.support .save .box.down {
  border-radius: 0 0 10px 10px;
  border-top: none;
}

.support .save .box.center {
  border-radius: 0;
}

.support .box .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.support .box .flex img {
  width: 24px;
  height: 24px;
}

.support .box .label {
  font-size: 10px;
  font-weight: 400;
  color: #9d9d9d;
}

.support .box .text {
  font-size: 14px;
  font-weight: 600;
}
