.market {
  /* max-width: 990px; */
  width: 100%;
  padding: 0 20px;
  padding-top: 12px;
}

.market.not {
  padding-top: 0;
}

.market .search_block {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.market .relative_input {
  width: 100%;
  position: relative;
}

.market .search {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
  border: 1px solid var(--border);
  padding-left: 50px;
}

.market .search::placeholder {
  color: var(--gray-color);
}

.market .icon {
  width: 24px;
  height: 24px;
}

.market .absolute {
  position: absolute;
  top: 0;
  left: 15px;
  bottom: 0;
  margin: auto;
}

.market .filter {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
}

.market .tabs {
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.market .tabs .tab {
  width: 100%;
  height: 35px;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  color: var(--black1);
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
}

.market .tabs .tab.first {
  border-radius: 8px 0 0 8px;
}

.market .tabs .tab.end {
  border-radius: 0 8px 8px 0;
}

.market .tabs .tab.active {
  background: var(--yellow);
  color: var(--white);
  border: 1px solid var(--yellow);
}

.market_over .category {
  padding: 25px 0;
  display: flex;
  overflow-y: hidden;
  background: transparent;
  gap: 8px;
  position: relative;
  z-index: 1;
}

.market_over .category::-webkit-scrollbar {
  display: none;
}

.market_over .cate {
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.market_over .cate .img {
  font-size: 20px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  object-position: center;
}

.market_over .cate p {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}

.market_over .cate:first-child {
  margin-left: 20px;
}

.market_over .cate:last-child {
  margin-right: 20px;
}

.market_over .cate.active {
  color: var(--white);
  background: var(--yellow);
}

.market_list h2 {
  font-size: 16px;
  font-weight: 500;
  color: var(--black1);
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.market_list h2 .icone {
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
}

.market_list .ovar_boxs {
  padding: 15px 0;
  display: flex;
  overflow-y: hidden;
  background: transparent;
  gap: 8px;
}

.market_list ::-webkit-scrollbar {
  display: none;
}

.market_list .ovar_boxs .box_markets {
  width: 150px !important;
}

.market_list .ovar_boxs .box_markets:first-child {
  margin-left: 20px;
}

.market_list .ovar_boxs .box_markets:last-child {
  margin-right: 20px;
}

.market_list .ovar_boxs .max-widht-box {
  width: 150px !important;
  height: 150px;
}

.market_banner_main img {
  border-radius: 10px;
  margin-bottom: 20px;
}

.marketing {
  width: 100%;
  padding: 0 20px;
}

.marketing .btns {
  margin-top: 12px;
  width: 100%;
  height: 50px;
  border-radius: 15px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
  border: 1px solid var(--border);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 5px 25px rgb(0, 0, 0, 0.05);
}

.marketing .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.marketing .btns p {
  font-size: 16px;
  font-weight: 500;
  color: var(--black1);
}

.marketing .btns .icon {
  font-size: 24px;
  color: var(--orange);
}

.all_category_items {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 0 20px;
}
