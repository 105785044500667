.mfa {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.mfa h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.mfa .qr-code {
  margin: 20px 0;
  background-color: white;
}

.mfa .qr-code svg {
  width: 200px;
  height: 200px;
}

.mfa .secret-key {
  border: 1px solid #9d9d9d;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.mfa .button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e22717;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
