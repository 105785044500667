.BonusesAndStatuses_container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 15px;
  font-size: 15px;
  padding-bottom: 100px;
}

.BonusesAndStatuses_container > div:nth-child(1) {
    width: 100%;
    height: 60px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: #e22717;
  }

  .BonusesAndStatuses_container > div:nth-child(1) svg {
    position: fixed;
    left: 10px;
    z-index: 1;
  }
  
  .BonusesAndStatuses_container > div > span {
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  
  .BonusesAndStatuses_container > div:nth-child(2){
    margin-top: 60px;
  }

  .BonusesAndStatuses_container > div > h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }

  .status {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    text-align: center;
    padding: 10px 10px;
    width: 100%;
  }
  
  .status > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    font-weight: 300;
  }
  
  .status > .active_status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    font-weight: 500;
    color: #e22717;
  }
  
  .status > div > h1 {
    font-size: 10px;
    font-weight: 400;
  }
  
  .status > div > p {
    font-size: 10px;
    font-weight: 400;
  }

  .skeleton-status {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: 100%;
  }
  
  .skeleton-status div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    font-weight: 300;
  }

  .tasks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
    margin: 20px 0 20px;
  }

  .tasks > div {
    background: var(--light-grey, #F3F3F3);
    border-radius: 10px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  .tasks > div p {
    color: #e22717;
    font-size: 14px;
    font-weight: 400;
  }

  .bonuses {
    background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
    border-radius: 10px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 20px;
  }

  .bonuses .balance p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
  }

  .bonuses .money {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .bonuses .money > div {
    display: grid;
    justify-items: center;
  }