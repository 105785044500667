.modal-category {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: white;
  z-index: 900;
  overflow-y: auto; /* Добавьте эту строку */
}

.modal-category .head_market {
  padding: 0 20px;
  height: 60px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border);
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: white;
  width: 100%;
}

.modal-category .head_market h1 {
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--black1);
}

body.modal-open {
  overflow: hidden;
}

/* .modal-category .category {
  position: fixed;
  z-index: 991;
  background-color: white;
} */
