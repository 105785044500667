.images {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  background: var(--white);
  color: #000;
}

.coin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin img {
  width: 180px;
}

.submit h1 {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  margin: 40px 0 10px;
}

.submit .text_gray {
  text-align: center;
  font-weight: 400;
  margin-bottom: 40px;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 300px;
  margin: 0 auto;
}

.inputs input {
  width: 320px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 20px;
  font-size: 18px;
  background: none;
  border: 1px solid #1A1A1A;
}

.inputs input:focus {
  border: 1px solid #E22717;
  transition: 0.4s;
}

.link {
  text-decoration: none;
  color: #535353;
  align-self: flex-end;
}

.forgot-password {
  width: 320px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #E22717;
  color: white;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password {
  position: relative;
}

.span-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.footer-text {
  text-align: center;
}

.register3 {
  color: #E22717;
}
