.notifictaion_container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 15px;
  font-size: 15px;
  padding-bottom: 80px;
}
.notifictaion_container > div:nth-child(1) {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #e22717;
  z-index: 1;
}

.notifictaion_container > div:nth-child(1) svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.notifictaion_container span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.notifictaion_container > div:nth-child(2){
  margin-top: 60px;
}

.item_notification > div > div {
  width: 100%;
  height: 100%;  
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 13.8px 0px #00000026;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 10px;
}

.item_notification > div > div > div > div {
  color: #9d9d9d;
  font-size: 8px;
  font-weight: 400;
}

.item_notification {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 5px 0 10px;
}

.item_notification > div {
  display: flex;
  align-items: end;
  gap: 5px;
}

.item_notification img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #868686;
}

.item_notification .notif_img {
  border-radius: 10%;
  width: 110px;
  height: 110px;
}

.item_notification > div {
  color: #343333;
}

.item_notification > div span {
  color: #000;
  font-weight: 500;
}

.notif_time {
  font-size: 10px;
  padding-top: 17px;
  color: #9d9d9d;
}
