.filter_market {
  width: 100%;
}

.filter_market .head_market {
  padding: 0 20px;
  height: 60px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border);
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: #fff;
}

.filter_market .head_market h1 {
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--black1);
}

.filter_market h3 {
  font-size: 16px;
  font-weight: 500;
  color: var(--black1);
}

.filter_market .value_div {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
  border: 1px solid var(--border);
  padding: 12px 15px;
}

.filter_market .value_div:focus-within {
  border: 1px solid #e22717;
  transition: 0.3s ease-in-out;
}
.filter_market .select_market {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: var(--black);
  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
}

.filter_market .select_market:focus {
  border-color: #e22717;
}

.filter_market .value_div span {
  font-weight: 500;
  color: rgb(168, 168, 168);
}

.filter_market .values-container {
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 25px 1fr;
}

.filter_market .filter_body {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter_market .sort_block {
  margin-top: 15px;
  padding: 16px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 5px 25px rgb(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.filter_market .toggle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #e22717;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_market .true {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e22717;
}

.filter_market .sort_box {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
  border-bottom: 1px solid var(--border);
}

.filter_market .sort_box:first-child {
  padding-top: 0;
}

.filter_market .sort_box:last-child {
  padding-bottom: 0;
  border: 0;
}

.filter_market .sort_box p {
  font-size: 15px;
  font-weight: 400;
  color: var(--black1);
}

.filter_market .btns {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter_market .btns .button_form {
  cursor: pointer;
  width: 100%;
  height: 50px;
  background: #e22717;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_market .btns .button_form.empty {
  padding: 12px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  gap: 8px;
  background: #cdcdcd;
}
