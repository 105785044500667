.store-calculator {
    padding: 20px 30px 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 50px;

    @media screen and (max-width: 800px) {
        padding: 20px 25px 40px;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .store-calculator_top {
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 100%;

        @media screen and (max-width: 500px) {
            gap: 30px;
        }

        .store-calculator_top_item {
            div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 25px;
                font-size: 16px;
                font-weight: 600;

                @media screen and (max-width: 500px) {
                    font-size: 10px;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
            }

            .custom-slider {
                -webkit-appearance: none;
                width: 100%;
                height: 6px;
                background: #ddd;
                border-radius: 3px;
                outline: none;

                @media screen and (max-width: 500px) {
                    height: 3px;
                }

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 12px;
                    height: 12px;
                    background: #e22717;
                    border-radius: 50%;
                    cursor: pointer;
                }

                &::-moz-range-thumb {
                    width: 100%;
                    height: 3px;
                    background: #e22717;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
    }

    .store-calculator_itogs {
        border: 1px solid var(--grey, #d9d9d9);
        border-radius: 10px;
        padding: 50px;

        @media screen and (max-width: 500px) {
            padding: 0;
            border: none;
        }

        h3 {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            color: #e22717;
            text-align: center;
            margin-bottom: 25px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                text-transform: none;
            }
        }

        .store-calculator_itogs_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
            }

            div {
                p {
                    margin-bottom: 20px;
                    
                    @media screen and (max-width: 500px) {
                        margin-bottom: 10px;
                    }
                }

                span {
                    font-weight: 600;
                }
            }
        }
    }
}
