/* ads-update.css */

.ads-update {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.ads-update form {
  display: flex;
  flex-direction: column;
}

.ads-update label {
  margin-bottom: 10px;
}

.ads-update input,
.ads-update textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.ads-update input:focus,
.ads-update textarea:focus {
  outline: none;
  border-color: #fdb602;
}

.ads-update button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: var(--orange);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ads-update button:hover {
  background-color: #b34500;
}

.ads-update button:focus {
  outline: none;
}

.ads-update button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.ads-update input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.ads-update input[type="number"]::-webkit-inner-spin-button,
.ads-update input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ads-update span {
  color: red;
  font-size: 12px;
}

.ads-update .image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
}
.image-container {
  position: relative;
  display: inline-block;
  /* margin: 10px; */
}

.image-item {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.image-actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.edit-icon,
.delete-icon {
  transition: transform 0.2s ease-in-out;
}

.edit-icon:hover,
.delete-icon:hover {
  transform: scale(1.1);
}
