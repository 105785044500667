.balance-card {
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(180deg, #FFAEA7 0%, #E22717 48.4%);
  padding: 20px;
  color: #fff;
}

.balance-card h1 {
  font-weight: 600;
  font-size: 12px;
}

.balance-card h2 {
  font-size: 24px;
  font-weight: 600;
}

.text-balance{
  font-family: 'Glasten';
  font-size: 20px;
  font-weight: 400;
}

.text-balance-coin {
  font-size: 14px;
  font-weight: 400;
}

.button-payment_home {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 40px 0 0;
  border-radius: 10px;
}

.button-payment_home button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  gap: 5px;
  cursor: pointer;
}

.button-payment_home button div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: #fff;
  border-radius: 50%;
}

.button-payment_home button div svg {
  color: #000;
  font-size: 20px;
}

.asman_coin_button img {
  width: 40px;
}

.asman_coin_button div {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-payment_home button > span {
  color: white;
}