.partners-card {
    background-color: #e22717;
    border-radius: 10px;
    // max-width: 240px;
    width: 100%;

    img {
        // width: 100%;
        height: 200px;

        @media screen and (max-width: 800px) {
            height: 65px;
        }
    }
}