.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0000000d;
    z-index: -1;
}
.header_store {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    padding: 30px 30px;

    @media (max-width: 800px) {
        padding: 10px 25px;
    }

    .logo {
        margin-right: 10px;
        a {
            font-family: "Glasten", sans-serif;
            font-size: 44px;

            @media (max-width: 800px) {
                font-size: 22.76px;
            }
        }
    }
    .store_menu {
        display: flex;
        align-items: center;
        gap: 30px;
        position: relative;

        @media (max-width: 1000px) {
            gap: 10px;
        }

        @media (max-width: 800px) {
            display: none;
        }

        li {
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 5px;
            white-space: nowrap;
            cursor: pointer;

            a {
                color: #1a1a1a;
            }
        }

        .drop_down {
            position: absolute;
            top: 50px;
            z-index: 1;
            border: 1px solid var(--grey, #D9D9D9);
            border-radius: 5px;
            background-color: #fff;
            width: 267px;

            li {
               border-bottom: 1px solid var(--grey, #D9D9D9); 
               padding: 15px 10px;
            }
        }

        .about {
            left: 35%;
        }

        .language {
            right: 0;
            width: 140px;
        }

        img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }
    }

    .menu {
        ul {
            display: block;
            
            li {
                margin-bottom: 10px;
            }
        }
    }

    .icon_menu {
        display: none;
        cursor: pointer;

        @media (max-width: 800px) {
            display: block;
        }
    }
}
