.notifictaion_containerrr {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Высота контейнера, чтобы он занимал весь экран */
  overflow-y: auto; /* Вертикальная прокрутка при переполнении */
  justify-content: flex-end; /* Содержимое прижато к низу */
  
}

.notification_listrr {
  display: flex;
  flex-direction: column;
}
