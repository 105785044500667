.tab-menu {
  width: 95%;
  background: var(--light-grey, #F3F3F3);
  border-radius: 50px;
  backdrop-filter: blur(15px);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  padding: 5px 0;
  margin-bottom: 20px;
  height: 70px;
}

.tab-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1A1A1A;
  width: 60px;
}

.active {
  color: #e22717;
}
