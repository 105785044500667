.questions_page {
    .questions_text {
        background-color: #e22717;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 42px 25px;

        @media screen and (max-width: 500px) {
            padding: 20px 25px;
        }

        p {
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            max-width: 720px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 19px;
            }
        }
    }

    .questions {
        padding: 50px 30px 100px;

        @media screen and (max-width: 500px) {
            padding: 0 25px 40px;
        }

        h6 {
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 50px 0 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                margin: 40px 0 20px;
            }
        }

        .question_accordion_block {
            display: grid;
            gap: 15px;

            @media screen and (max-width: 500px) {
                gap: 10px;
            }

            .question_accordion {
                background-color: #f3f3f3;
                box-shadow: none;
                border-radius: 10px;
                
                &::before {
                    display: none;
                }

                #panel1-header {
                    font-size: 16px;
                    font-weight: 600;

                    @media screen and (max-width: 500px) {
                        font-size: 8px;
                    }
                }

                .css-15v22id-MuiAccordionDetails-root {
                    font-size: 14px;
                    line-height: 21px;

                    @media screen and (max-width: 500px) {
                        font-size: 8px;
                        line-height: 12px;
                    }
                }
            }
        }
    }
}
