.card_box {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.card_box .max {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--border);
}

.card_box .box_slide {
  width: 100%;
  outline: none;
  position: relative;
}

.card_box .slick-dots {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 35px;
  width: 70%;
  height: 20px;
  overflow: hidden;
}

.card_box .slick-dots li {
  display: inline-block;
  margin: 0 auto;
}

.card_box .box_slide div {
  outline: none;
}

.card_box .card_image {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
}

.card_box .contant {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card_box .contant h3 {
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 18px;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card_box .contant .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_box .price {
  font-size: 15px;
  font-weight: 500;
  color: var(--yellow);
}

/* .card_box .cart {
  padding: 2px;
  border-radius: 3px;
  border: 1px solid var(--border);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
} */

.card_box .cart .icond {
  font-size: 18px;
  color: var(--yellow);
}

.card_box .love {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 24px !important;
  height: 24px !important;
  z-index: 3;
}