.product-card {
  width: 48%;
  height: 100%;
  min-height: 100px;
  /* box-shadow: 2px 3px 14px #ccc; */
  border-radius: 10px;
  position: relative;
}

.product-card .product-card__image img {
  width: 100%;
  height: 159px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

/* .product-card__info__price2 {
  font-size: 12px;
  color: #777777;
} */

.product-card__info {
  padding: 10px 0;
}

.product-card__info__price {
  font-weight: 400;
  font-size: 10px;
}

.product-card__info__title {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: #e22717;
  margin-bottom: 10px;
}

.product-card__info__description {
  font-size: 8px;
  color: #777777;
}

.product-card__top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 30px;
  background-color: white;
  display: flex;
  font-size: 10px;
  padding: 4px;
  justify-content: space-between;
}

.product-card__top__user {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-card__like {
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.product-card__top__message {
  position: absolute;
  right: 5px;
  bottom: 40px;
}

.product-card__top__user__message {
  position: fixed; /* Change to fixed or remove for default positioning */
  top: 50%; /* Position from top */
  left: 50%; /* Position from left */
  transform: translate(-50%, -50%); /* Center the modal */
  width: 250px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  padding: 10px;
}

.product-card__top__user__message__text {
  padding: 10px;
}

.product-card__top__user__message__text h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.product-card__top__user__message__option {
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 4px;
  transition: background 0.3s;
}

.product-card__top__user__message__option:hover {
  background-color: #e9e9e9;
}

.product-card__top__user__message__close {
  padding: 5px;
  cursor: pointer;
  color: #888;
  text-align: center;
  font-size: 18px;
}

.product-card__top__user__message button:hover {
  background-color: darkorange;
}
