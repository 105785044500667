.message-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.message-card {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.message-card .profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-card .message-content {
  flex: 1;
  font-size: 14px;
}

.message-card .message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}

.message-card .message-header h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.message-card .message-header span {
  font-size: 10px;
  color: #9d9d9d;
  font-weight: 400;
}

.message-card .message-content p {
  margin: 5px 0 0 0;
  font-size: 12px;
  font-weight: 400;
  color: #9d9d9d;
}

.message-card .is-read-message {
  width: 10px;
  height: 10px;
  background-color: #e22717;
  border-radius: 50%;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.search-bar input {
  width: 100%;
  font-size: 10px;
}
