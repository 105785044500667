.section-payments {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 80px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.section-payments_items {
  color: white;
  font-weight: 300;
  border: 1px solid var(--black1);
  border-radius: 10px;
  padding: 5px;
}

.section-payments_items > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--black1);
  padding: 10px 0;
  width: 100%;
}

.section-payments_items > h1 {
  font-size: 11px;
  color: #999999;
  padding: 10px;
}

.detail_count_status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.button_period {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--grey, #D9D9D9);
}

.button_period:active {
  color: var(--orange);
}

.contain-date-block {
  display: flex;
  justify-content: space-around;
}

.contain-date-block > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.contain-date-block > div input {
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #ccc;
  font-size: 12px;
}

.text-modal-date {
  text-align: center;
  margin: 10px 0 20px;
  font-size: 14px;
  font-weight: 400;
}

.contain-date-block2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
  font-size: 12px;
}

.contain-date-block2 button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  transition: 1s all;
  font-weight: 600;
}

.contain-date-block2 button:active,
.contain-date-block2 button:hover {
  color: #e22717;
}

.payments_header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e22717;
  height: 60px;
  position: fixed;
  inset: 0;
}

.payments_header > svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}

.payments_header span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}
