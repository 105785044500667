.servises_slick_detail {
    background: linear-gradient(98.58deg, #223DFC 63.55%, #0393FC 82.21%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 245px;
    padding: 20px;
    font-size: 10px;
    font-weight: 900;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
}

.servises_slick_detail p {
    background-color: #e22717;
    border-radius: 50px;
    padding: 10px;
    white-space: nowrap;
    margin-top: 5px;
}