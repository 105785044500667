.how_neocoin_works {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 30px 100px;

    @media screen and (max-width: 800px) {
        padding: 0 25px 40px;
    }

    .how_neocoin_works_block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
        gap: 40px;

        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }

        > div {
            max-width: 350px;

            @media screen and (max-width: 500px) {
                display: none;
            }

            img {
                width: 100%;
                border-radius: 6px;
            }
        }

        p {
            font-size: 20px;
            line-height: 25px;
            max-width: 420px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 19px;

                > br {
                    display: none;
                }
            }
        }
    }

    .how_neocoin_works_consensus {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
            gap: 10px;
            padding: 0 20px;
        }

        div {
            border: 1px solid var(--grey, #d9d9d9);
            border-radius: 10px;
            padding: 34px 10px;
            font-size: 20px;
            font-weight: 600;
            color: #e22717;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                padding: 20px 10px;
            }

            span {
                display: block;
                font-weight: 400;
                color: #1a1a1a;
            }
        }
    }

    .how_neocoin_works_bloks {
        margin-top: 100px;

        @media screen and (max-width: 800px) {
            margin-top: 40px;

            hr {
                display: none;
            }
        }

        .how_neocoin_works_bloks_item {
            display: grid;
            grid-template-columns: 0.6fr 1fr;
            align-items: center;
            padding: 50px 0;

            @media screen and (max-width: 800px) {
                grid-template-columns: 1fr;
                gap: 10px;
                padding: 0 0 30px;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 15px;

                div {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: #e22717;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: 20px;
                    font-weight: 600;

                    @media screen and (max-width: 500px) {
                        width: 30px;
                        height: 30px;
                        font-size: 12px;
                    }
                }

                p {
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #e22717;

                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                    }
                }
            }

            span {
                font-size: 16px;
                width: 460px;

                @media screen and (max-width: 800px) {
                    width: 100%;
                }

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }
        }
    }
}
