.global-market-page {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 20px 15px;
  margin: 0 auto;
  overflow-x: hidden;
}
/* 
.global-market-page .banner-block img {
  border-radius: 10px;
} */

.global-market-page .search-block {
  display: flex;
  align-items: center;
}

.global-market-page .search-block .search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 10px;
  outline: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.global-market-page .search-block .filter-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--grey, #D9D9D9);
  margin-right: 10px;
}

.global-market-page .tabs {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 13px;
}

.global-market-page .tab1 {
  flex: 1;
  padding: 10px 20px;
  background-color: #eee;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.global-market-page .tab2 {
  flex: 1;
  padding: 10px 20px;
  background-color: #eee;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.global-market-page .tab1.active {
  background-color: #e22717;
  color: white;
}

.global-market-page .tab2.active {
  background-color: #e22717;
  color: white;
}

.global-market-page .tab-block {
  margin-top: 10px;
}

.global-market-page .resultSearch {
  width: 100%;
  min-height: 50vh;
  height: 100%;
  background: white;
  border: 1px solid #ccc;
  margin-top: 10px;
  border-radius: 10px;
}
