.birzha {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.content {
    padding: 100px 20px;
    padding-bottom: 120px;
}

.content h2 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}