.contacts-card {
    margin-right: 10px;
    display: grid;
    justify-items: center;
    gap: 5px;
    cursor: pointer;
}

.contacts-card div {
    background: var(--light-grey, #F3F3F3);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    color: #e22717;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contacts-card p {
    font-size: 8px;
}