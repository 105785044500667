.section1 {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 800;
  padding-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
}
.section1 > h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.status_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
}

.boxx {
  background: #F3F3F3;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
}

.boxx h3 {
  color: #e22717;
}

.expenses {
  display: grid;
  gap: 5px;
}

.bonus_status {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.bonus_status div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bonus_status div img {
  width: 17px;
  height: 17px;
}

.curs-usdt {
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 12px;
  font-size: 12px;
}

.curs-usdt div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  border: 1px solid var(--grey, #D9D9D9);
  border-radius: 5px;
  padding: 5px 10px;
}

.curs-usdt div p {
  font-size: 12px;
  font-weight: 500;
}

.curs-usdt div span {
  background: var(--light-grey, #F3F3F3);
  padding: 5px 10px;
  border-radius: 5px;
  color: #e22717;
  font-weight: 600;
}

.payment-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  gap: 10px;
}

.status-user {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
}

/* .contain-button_item_home {
  width: 100%;
  padding: 4px;
  background: var(--light-grey, #F3F3F3);
  border-radius: 5px;
  border: 1px solid #787878;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .child-button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
} */

/* .child-button_qr {
  display: grid;
  gap: 10px;
} */

/* .child-button_qr button {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px;
  gap: 6px;
  border-radius: 10px;
  background: var(--light-grey, #F3F3F3);
} */

.child-button_qr button:active,
.contain-button_item_home:active,
.referal-contain > div:active {
  color: #e22717;
}



/* .status-user {
  padding: 10px 0;
  color: #e22717;
} */

/* .referal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
} */

.referal-contain > div {
  background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.text-referal-home_page_main {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  margin-top: 50px;
}

.text-referal-home_page {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.asman-coin-contain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000000;
  gap: 10px;
}

.asman-coin-referal {
  width: 200px;
  margin: 20px 0 40px;
}

.asman-coin-contain > div {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--grey, #D9D9D9);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.asman-coin-contain > div div {
  display: flex;
  align-items: center;
}

.asman-coin-contain div h1 {
  font-size: 12px;
  font-weight: 500;
  color: #9d9d9d;
}

.asman-coin-contain div p {
  font-size: 10px;
}

.asman-coin-contain button {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  background: linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%);
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.button-referal-home_page {
  cursor: pointer;
}

.copyed-referal {
  color: green;
  position: absolute;
  top: 5px;
}

/* .footer_home_page {
  border-bottom: 1px solid #3a3a3a;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 20px;
}

.footer_home_page span {
  color: #9e9e9e;
}

.footer_home_page p {
  font-size: 10px;
  color: #8d8d8d;
  text-align: center;
  font-weight: 500;
  padding: 10px;
} */

/* .list_count_user img {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.list_count_user li {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 8px;
  margin-top: 2px;
} */

/* .footer_home_page h2 {
  color: var(--orange);
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.count_user_in_asman {
  background-color: white;
  padding: 2px 5px;
  border-radius: 2px;
  margin-top: 10px;
} */