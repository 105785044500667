.message-details-page {
  display: flex;
  flex-direction: column;
  height: 95vh;
  position: relative;
  overflow: hidden;
}

.message-details-page > .message-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #e22717;
  position: sticky;
  top: 0;
  z-index: 1;
}

.message-details-page .message-details-profile {
  display: flex;
  align-items: center;
}

.message-details-page .message-details-profile h4 {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}

.message-details-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-details-icons svg {
  cursor: pointer;
}

.message-details-page .message-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message-details-page .message-card {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  border-radius: 10px;
  background-color: #f1f1f1;
  max-width: 80%;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 10px;
}

.message-details-page .message-card.sent {
  align-self: flex-end;
  background-color: #e22717;
  width: 80%;
}

.message-details-page .message-card.received {
  align-self: flex-start;
  background: var(--light-grey, #F3F3F3);
  width: 70%;
}

.message-details-page .profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px 0 5px;
}

.message-details-page .message-content {
  flex: 1;
}

.message-details-page .message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-details-page .message-header h4 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.message-details-page .message-header span {
  font-size: 12px;
  color: #888;
}

.message-details-page .message-content p {
  margin: 5px 0 0 0;
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

.message-details-page .send-message-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 20px;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.send-message-form .send-message-input {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #1A1A1A;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin-right: 5px;
}

.message-details-page .send-message-form input {
  font-size: 14px;
  outline: none;
}

.message-details-page .send-message-form .send-message {
  display: flex;
  align-items: center;
  gap: 5px;
}

.message-details-page .send-message-form .send-message-button {
  padding: 8px;
  border: none;
  border-radius: 50%; 
  background-color: #e22717;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.message-details-page .send-message-form .send-message-button.disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.message-details-page
  .send-message-form
  .send-message-button:hover:not(.disabled) {
  background-color: #fb4837;
}

.message-details-page .is-read-message-details {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}

.message-details-page .is-read-message-details svg {
  width: 16px;
  height: 16px;
  fill: #1c6fab;
}

.fixed-ad-info {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.fixed-ad-info .ad-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.ad-details h3 {
  font-size: 12px;
  margin: 0;
  color: #fff;
}
