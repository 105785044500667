.ads_detail {
  width: 100%;
  padding-bottom: 100px;
}

.ads_detail .head_detail {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.ads_detail .slick-dots {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 35px;
  width: 70%;
  height: 20px;
  overflow: hidden;
}

.ads_detail .slick-dots li {
  display: inline-block;
  margin: 0 auto;
}

.ads_detail .contants {
  padding: 0 20px;
  padding-top: 10px;
}

.ads_detail .contants .description span{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ads_detail .contants .description p{
  font-size: 12px;
  font-weight: 400;
}

.ads_detail .box_slide div {
  outline: none;
}

.ads_detail .between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ads_detail .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ads_detail .id {
  font-size: 15px;
  font-weight: 300;
  color: var(--black1);
}

.ads_detail .loves {
  width: 24px;
  height: 24px;
}

.ads_detail .title {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  margin: 10px 0 20px;
}

.ads_detail .prices {
  font-size: 16px;
  font-weight: 600;
}

.ads_detail .ads_detail_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.ads_detail .ads_detail_buttons button {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s;
}

.ads_detail .ads_detail_buttons button:hover {
  opacity: 80%;
}

.ads_detail .user {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.ads_detail .user_box {
  margin: 20px 0 20px;
}

.ads_detail .user_box span {
  font-size: 16px;
  font-weight: 600;
}

.ads_detail .user.r {
  border-top: 0;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.ads_detail .ads_detail_photo {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
}

.ads_detail .user img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
/* 
.ads_detail .ovar_boxs {
  padding: 15px 0;
  display: flex;
  overflow-y: hidden;
  background: transparent;
  gap: 8px;
} */
/* 
.ads_detail .ovar_boxs .box_markets {
  display: flex;
}

.ads_detail .ovar_boxs .box_markets:first-child {
  margin-left: 20px;
}

.ads_detail .ovar_boxs .box_markets:last-child {
  margin-right: 20px;
}

.ads_detail .ovar_boxs .max-widht-box {
  width: 150px !important;
  height: 150px;
} */

.ads_detail .line_boxs h2 {
  font-size: 16px;
  font-weight: 600;
}

.ads_detail .ovar_boxs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 10px;
}

.ads_detail .user_title {
  font-size: 16px;
  font-weight: 400;
  color: var(--black1);
}

.ads_detail ::-webkit-scrollbar {
  display: none;
}

.ads_detail .texting {
  font-size: 13px;
  font-weight: 400;
  color: #888;
  line-height: 1;
}
