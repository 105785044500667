.notifictaionpage_container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}

.notifictaionpage_container span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.notifictaionpage_container .notification_item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 10px;
  border: 1px solid var(--grey, #D9D9D9);
  cursor: pointer;
  justify-content: space-between;
}

.notifictaionpage_container .notification_item > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notifictaionpage_container > div:nth-child(1) {
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #e22717;
}

.notifictaionpage_container > div:nth-child(1) svg {
  position: fixed;
  left: 10px;
  z-index: 1;
}
