.advantages-card {
    border: 1px solid var(--grey, #D9D9D9);
    border-radius: 10px;
    padding: 40px;
    margin-right: 20px;
    width: 340px;
    height: 285px;

    @media screen and (max-width: 500px) {
        width: 230px;
        height: 170px;
        padding: 25px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        color: #e22717;
        margin-bottom: 20px;

        @media screen and (max-width: 500px) {
            font-size: 12px;
        }
    }

    p {
        font-size: 14px;
        color: #353535;

        @media screen and (max-width: 500px) {
            font-size: 8px;
        }
    }
}