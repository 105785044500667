.store-button {
    border: 2px solid #1a1a1a;
    border-radius: 50px;
    padding: 10px;
    width: 249px;

    @media screen and (max-width: 500px) {
        font-size: 14px;
        font-weight: 600;
    }
}
