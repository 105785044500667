/* .images2 {
  width: 100%;
  height: 100vh;
  padding-top: 20px;
  color: white;
  background-color: black;
} */

.container2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 30px;
  color: black;
  background-color: white;
}

.coin2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin2 img {
  width: 180px;
}

.submit2 h1 {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  margin: 20px 0;
}

.inputs2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  width: 300px;
  margin: 0 auto;
}

.inputs2 input {
  width: 320px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 20px;
  font-size: 18px;
  border: 1px solid #1A1A1A;
}

.inputs2 input:focus {
  border: 1px solid #E22717;
  transition: 0.4s;
}

.inputs2 input::placeholder {
  color: #1A1A1A;
  letter-spacing: 1px;
  font-size: 15px;
}

.link2 {
  text-decoration: none;
  text-decoration: underline;
}

.sign-up2 {
  width: 320px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #E22717;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.password2 {
  position: relative;
}

.span-icon2 {
  position: absolute;
  top: 17px;
  right: 15px;
  cursor: pointer;
}

.footer-text2 {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-bottom: 50px;
}

.register2 {
  color: #E22717;
}

.error-text2 {
  color: red;
  position: absolute;
}

.sign-up2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rules_register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rules_register input {
  width: 20px;
}

.modalsss {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #000000;
}

.modal-contentsss {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.closesss {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closesss:hover,
.closesss:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-messagesss {
  font-size: 18px;
  text-align: center;
}

.button-closesss {
  background-color: #aaa;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.button-closesss:hover {
  background-color: #555;
}
